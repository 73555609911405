<template>
  <div style="padding: 4rem 2rem" class="vertical gapped left">
    <!--  -->
    <v-btn
      color="primary"
      large
      rounded
      absolute
      top
      left
      style="z-index: 10; width: auto"
      to="/"
    >
      <v-icon>mdi-chevron-left</v-icon>
      Zurück
    </v-btn>
    <h1>Impressum</h1>
    <h2>SoxenTec UG (haftungsbeschränkt)</h2>
    <b>Geschäftsführer: Andreas Bordon-Philipp</b>
    <v-card
      outlined
      style="border-radius: 2rem; padding: 1rem 2rem"
      href="https://goo.gl/maps/8GtsTguaX29s8Exk9"
      target="_blank"
    >
      <div class="horizontal gapped centered">
        <v-icon color="primary" x-large>mdi-map-marker</v-icon>
        <p style="height: 100%; margin: auto 0">
          Fischertal 84 <br />
          D-42287 Wuppertal
        </p>
      </div>
    </v-card>

    <div class="horizontal gapped">
      <v-card
        outlined
        style="border-radius: 2rem; padding: 1rem 2rem"
        href="tel:+492022838850"
      >
        <div
          class="horizontal gapped centered"
          style="margin: auto 0; height: 100%"
        >
          <v-icon color="primary" x-large>mdi-phone</v-icon>
          <p style="margin: auto">+49 202 2838 850</p>
        </div>
      </v-card>
      <v-card
        outlined
        style="border-radius: 2rem; padding: 1rem 2rem"
        href="tel:+491739217775"
      >
        <div
          class="horizontal gapped centered"
          style="margin: auto 0; height: 100%"
        >
          <v-icon color="primary" x-large>mdi-cellphone</v-icon>
          <p style="margin: auto">+49 173 921 7775</p>
        </div>
      </v-card>
      <v-card
        outlined
        style="border-radius: 2rem; padding: 1rem 2rem"
        href="mailto:solar@soxentec.de"
      >
        <div
          class="horizontal gapped centered"
          style="margin: auto 0; height: 100%"
        >
          <v-icon color="primary" x-large>mdi-email</v-icon>
          <p style="margin: auto">solar@soxentec.de</p>
        </div>
      </v-card>
    </div>

    <div class="vertical" style="margin-top: 2rem">
      Amtsgericht Wuppertal HRB 29982 ● USt-Nr.: 131/5930/2768 ● DE287314550
      <br /><br />
      Inhaltlich verantwortlich gemäß § 5 TMG und § 10 Absatz MDStV ist SoxenTec
      UG, Geschäftsführer: Andreas Bordon-Philipp
      <br /><br />
      Haftungshinweis: Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir
      keine Haftung für die Inhalte externer Links. Für den Inhalt der
      verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
      Weiter weisen wir darauf hin, dass die Webseite www.soxenrod.de von Inhalt
      und Aufbau urheberrechtlich geschützt ist. <br /><br />
      Copyright: Die erhaltenen Daten und Informationen sowie Abbildungen dürfen
      nur mit ausdrücklicher Zustimmung von SoxenRod Consult (Andreas
      Bordon-Philipp) verwendet und/oder vervielfältigt werden.
    </div>
  </div>
</template>
<script>
export default {
  //
};
</script>
<style lang="scss" scoped >
//
</style>
